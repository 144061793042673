export const nocMap = {
    '0 to 3 (Early childhood)': {
        'Autism(ASD)': '/assets/noc-images/NOCST_EarlyChildhood-Autism-TravisAFB-EFMP.png',
        Celiac: '/assets/noc-images/NOCST_EarlyChildhood-CeliacDisease-TravisAFB-EFMP.png',
        'Cerebral Palsy': '/assets/noc-images/NOCST_EarlyChildhood-CerebralPalsy-TravisAFB-EFMP.png',
        'Down Syndrome': '/assets/noc-images/NOCST_EarlyChildhood-DownSyndrome-TravisAFB-EFMP.png',
        'General – Heart-Blood-Lung': '/assets/noc-images/NOCST_EarlyChildhood-HeartBloodLung-TravisAFB-EFMP.png',
        'Sensory Processing Disorder':
            '/assets/noc-images/NOCST_EarlyChildhood-SensoryProcessingDisorder-TravisAFB-EFMP.png',
        'Speech Impairment': '/assets/noc-images/NOCST_EarlyChildhood-SpeechImpairment-TravisAFB-EFMP.png',
        'Visual Impairment': '/assets/noc-images/NOCST_EarlyChildhood-VisualImpairment-TravisAFB-EFMP.png'
    },
    '4 to 12 (Education)': {
        Anxiety: '/assets/noc-images/NOCST_Education-Anxiety-TravisAFB-EFMP.png',
        Asthma: '/assets/noc-images/NOCST_Education-Asthma-TravisAFB-EFMP.png',
        'Attention Deficit Disorder': '/assets/noc-images/NOCST_Education-AttentionDeficitDisorder-TravisAFB-EFMP.png',
        'Autism(ASD)': '/assets/noc-images/NOCST_Education-Autism-TravisAFB-EFMP.png',
        Celiac: '/assets/noc-images/NOCST_Education-CeliacDisease-TravisAFB-EFMP.png',
        'Cerebral Palsy': '/assets/noc-images/NOCST_Education-CerebralPalsy-TravisAFB-EFMP.png',
        'Down Syndrome': '/assets/noc-images/NOCST_Education-DownSyndrome-TravisAFB-EFMP.png',
        'General – Heart-Blood-Lung': '/assets/noc-images/NOCST_Education-HeartBloodLung-TravisAFB-EFMP.png',
        'Oppositional Defiance Disorder':
            '/assets/noc-images/NOCST_Education-OppositionalDefianceDisorder-TravisAFB-EFMP.png',
        'Sensory Processing Disorder':
            '/assets/noc-images/NOCST_Education-SensoryProcessingDisorder-TravisAFB-EFMP.png',
        'Speech Impairment': '/assets/noc-images/NOCST_Education-SpeechImpairment-TravisAFB-EFMP.png',
        'Visual Impairment': '/assets/noc-images/NOCST_Education-VisualImpairment-TravisAFB-EFMP.png'
    },
    '13 to 21 (Transition)': {
        Anxiety: '/assets/noc-images/NOCST_Transition-Anxiety-TravisAFB-EFMP.png',
        'Attention Deficit Disorder': '/assets/noc-images/NOCST_Transition-AttentionDeficitDisorder-TravisAFB-EFMP.png',
        'Autism(ASD)': '/assets/noc-images/NOCST_Transition-Autism-TravisAFB-EFMP.png',
        Celiac: '/assets/noc-images/NOCST_Transition-CeliacDisease-TravisAFB-EFMP.png',
        'Cerebral Palsy': '/assets/noc-images/NOCST_Transition-CerebralPalsy-TravisAFB-EFMP.png',
        'Down Syndrome': '/assets/noc-images/NOCST_Transition-DownSyndrome-TravisAFB-EFMP.png',
        'General – Heart-Blood-Lung': '/assets/noc-images/NOCST_Transition-HeartBloodLung-TravisAFB-EFMP.png',
        'Oppositional Defiance Disorder':
            '/assets/noc-images/NOCST_Transition-OppositionalDefianceDisorder-TravisAFB-EFMP.png',
        'Sensory Processing Disorder':
            '/assets/noc-images/NOCST_Transition-SensoryProcessingDisorder-TravisAFB-EFMP.png',
        'Speech Impairment': '/assets/noc-images/NOCST_Transition-SpeechImpairment-TravisAFB-EFMP.png',
        'Visual Impairment': '/assets/noc-images/NOCST_Transition-VisualImpairment-TravisAFB-EFMP.png'
    },
    '22 and older (Adult)': {
        'Acute Lymphocytic Leukemia': '/assets/noc-images/NOCST_Adult-AcuteLymphocyticLeukemia-TravisAFB-EFMP.png',
        Allergies: '/assets/noc-images/NOCST_Adult-Allergies-TravisAFB-EFMP.png',
        Anxiety: '/assets/noc-images/NOCST_Adult-Anxiety-TravisAFB-EFMP.png',
        Asthma: '/assets/noc-images/NOCST_Adult-Asthma-TravisAFB-EFMP.png',
        'Autism(ASD)': '/assets/noc-images/NOCST_Adult-Autism-TravisAFB-EFMP.png',
        'Down Syndrome': '/assets/noc-images/NOCST_Adult-DownSyndrome-TravisAFB-EFMP.png',
        'General – Heart-Blood-Lung': '/assets/noc-images/NOCST_Adult-HeartBloodLung-TravisAFB-EFMP.png',
        Hypothyroidism: '/assets/noc-images/NOCST_Adult-Hypothyroidism-TravisAFB-EFMP.png',
        'Kidney Disease': '/assets/noc-images/NOCST_Adult-KidneyDisease-TravisAFB-EFMP.png',
        'Visual Impairment': '/assets/noc-images/NOCST_Adult-VisualImpairment-TravisAFB-EFMP.png'
    }
};
