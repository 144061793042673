import React, { FC, useState } from 'react';
import { IonContent, IonPage, IonGrid } from '@ionic/react';
import { FormHelperText } from '@material-ui/core';
import { Field, FormSpy } from 'react-final-form';
import { TextField } from 'mui-rff';
import { LogoCard } from '../../components/LogoCard';
import { DiseaseCareMapRequestForm } from './DiseaseCareMapRequestForm';
import { CSEmailField, CSTextField, CSPhoneField, CSSelectField } from '../../components/Fields';

// const Li: FC = ({ children }) => <li style={{ marginBottom: '5px' }}>{children}</li>;

const Title = <>CareStarter Disease Management CareMap&trade; Request</>;

const Subtitle = <></>;

const DiseaseCareMapRequestPage: FC = () => {
    const [showEmailHelper, setShowEmailHelper] = useState(true);
    return (
        <IonPage>
            <IonContent fullscreen>
                <IonGrid>
                    <LogoCard title={Title} subtitle={Subtitle}>
                        <DiseaseCareMapRequestForm>
                            <FormSpy
                                subscription={{ values: true }}
                                render={({ values }) => {
                                    setShowEmailHelper(!(values.email && values.email.length));
                                    return null;
                                }}
                            />
                            <CSEmailField label="Requestor Email" name="requestor_email" required />
                            <CSTextField label="Patient First Name" name="patient_first_name" required />
                            <CSTextField label="Patient Last Name" name="patient_last_name" required />
                            <CSEmailField label="Patient Email" name="patient_email" required />
                            {showEmailHelper && <FormHelperText>Civilian email recommended</FormHelperText>}
                            <CSPhoneField label="Patient Mobile Phone (xxx-xxx-xxxx)" name="patient_mobile_phone" />
                            <CSSelectField required label={`Requested Caremap`} name={'requested_caremap'}>
                                <option aria-label="None" value="" />
                                <option value={'Asthma'}>Asthma</option>
                                <option value={'Diabetes'}>Diabetes</option>
                                <option value={'Hypertension'}>Hypertension</option>
                            </CSSelectField>
                            <TextField
                                label="Base"
                                name="base"
                                required
                                value="Travis Air Force BASE - EFMP"
                                placeholder="Travis Air Force BASE - EFMP #16888"
                                disabled
                            />
                            <Field name="requestor_type" component="input" type="hidden" required value="EFMP-M" />
                        </DiseaseCareMapRequestForm>
                    </LogoCard>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default DiseaseCareMapRequestPage;
