import React, { FC, ReactElement } from 'react';
import { Checkboxes, Select, TextField } from 'mui-rff';
import { Tooltip } from '@material-ui/core';

const formatPhoneNumber = (phoneNumberString: string): string => {
    if (!phoneNumberString || phoneNumberString.length === 0) return '';
    const input = phoneNumberString.replace(/\D/g, '').substring(0, 10); // First ten digits of input only
    const areaCode = input.substring(0, 3);
    const middle = input.substring(3, 6);
    const last = input.substring(6, 10);
    return `${areaCode}` + (input.length > 3 ? `-${middle}` + (input.length > 6 ? `-${last}` : '') : '');
};

const validateEmail = (val: string) => {
    return /\S+@\S+\.\S+/.test(val) ? undefined : 'Please enter a valid email';
};

const validatePhone = (val: string) => {
    return val && val.split('-').join('').length < 10 ? 'Please enter a valid phone number' : undefined;
};

interface FieldProps {
    label: string;
    name: string;
    required?: boolean;
    disabled?: boolean;
}

interface FieldPropsToolTip {
    label: string;
    name: string;
    required?: boolean;
    disabled?: boolean;
    tooltip: string | ReactElement;
}

interface TextFieldProps extends FieldProps {
    validate?: (val: string) => string | undefined;
    format?: (val: string) => string | undefined;
    maxLength?: number;
    placeholder?: string;
    value?: string;
}

// CSTextField serves as the base for most other fields.
export const CSTextField: FC<TextFieldProps> = ({
    label,
    name,
    required,
    validate,
    format,
    maxLength,
    disabled,
    placeholder,
    value
}) => {
    return (
        <TextField
            label={label}
            role={'input'}
            id={name}
            InputLabelProps={{
                htmlFor: name,
                id: name + '-label'
            }}
            inputProps={{
                'aria-disabled': disabled,
                maxLength: maxLength
            }}
            fieldProps={{
                validate: validate
                    ? validate
                    : (val: string) =>
                          required ? (val && val.length ? undefined : 'This field is required') : undefined,
                format: format,
                parse: format,
                maxLength: maxLength
            }}
            name={name}
            required={required}
            disabled={disabled}
            value={value}
            placeholder={placeholder}
        />
    );
};

export const CSPhoneField: FC<FieldProps> = ({ label, name, required }) => {
    return (
        <CSTextField
            label={label}
            format={formatPhoneNumber}
            maxLength={10}
            validate={validatePhone}
            name={name}
            required={required}
        />
    );
};

export const CSDateField: FC<FieldProps> = ({ label, name, required }) => {
    return <TextField label={''} name={name} required={required} type={'date'} />;
};

export const CSEmailField: FC<FieldProps> = ({ label, name, required }) => {
    return <CSTextField label={label} validate={validateEmail} name={name} required={required} />;
};

export const CSSelectField: FC<FieldProps> = ({ label, name, required, children, disabled }) => {
    return (
        <Select
            required={required}
            name={name}
            label={label}
            disabled={disabled}
            native
            MenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                }
            }}
            fieldProps={{
                validate: (val: string) =>
                    required ? (val && val.length ? undefined : 'This field is required') : undefined
            }}
            inputLabelProps={{
                htmlFor: name,
                role: name + '-label',
                'aria-label': name
            }}
            inputProps={{
                role: name + '-select'
            }}
        >
            {children as ReactElement[]}
        </Select>
    );
};

export const CSSelectFieldToolTip: FC<FieldPropsToolTip> = ({ label, name, tooltip, required, children, disabled }) => {
    return (
        <Tooltip title={tooltip} PopperProps={{ style: { zIndex: 0, whiteSpace: 'pre-line' } }} placement="left">
            <div>
                <Select
                    required={required}
                    name={name}
                    label={label}
                    disabled={disabled}
                    native
                    MenuProps={{
                        getContentAnchorEl: null,
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left'
                        }
                    }}
                    fieldProps={{
                        validate: (val: string) =>
                            required ? (val && val.length ? undefined : 'This field is required') : undefined
                    }}
                    inputLabelProps={{
                        htmlFor: name,
                        role: name + '-label',
                        'aria-label': name
                    }}
                    inputProps={{
                        role: name + '-select'
                    }}
                >
                    {children as ReactElement[]}
                </Select>
            </div>
        </Tooltip>
    );
};

interface CheckboxProps extends FieldProps {
    data: Array<{ label: string; value: string }>;
    onClick?: (e: any) => void;
}

export const CSCheckBoxes: FC<CheckboxProps> = ({ label, name, required, disabled, data, onClick }) => {
    return (
        <Checkboxes
            name={name}
            label={label}
            color="primary"
            required={required}
            disabled={disabled}
            onClick={onClick}
            formGroupProps={{
                style: {
                    display: 'grid',
                    gridAutoFlow: 'row',
                    gridTemplateColumns: 'repeat(3, 1fr)',
                    gridTemplateRows: 'repeat(1, 1fr)'
                }
            }}
            formControlProps={{
                style: {
                    marginTop: '24px'
                }
            }}
            data={data}
        />
    );
};
