import React, { FC, useState } from 'react';
import {
    Button,
    Card,
    CardContent,
    Dialog,
    Typography,
    DialogTitle,
    Box,
    IconButton,
    CircularProgress,
    Divider,
    useMediaQuery,
    Tooltip
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { FormSpy, withTypes } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import useFetch from 'use-http';
import { FormValues, Patient } from './types';
import { CSSelectField, CSTextField, CSCheckBoxes } from '../../components/Fields';
import { diagnoses } from './diagnoses';

const getPatientText = (index: number) => {
    return index ? `Patient ${index + 1}` : 'Patient';
};

const cleanPatient = {
    patient_age: '',
    patient_primary_diagnosis: '',
    care_required: [],
    med_supplies_needed: '',
    behavioral_services_needed: []
};

const initialValues = {
    base: 'Travis Air Force Base - EFMP #16888',
    form_name: 'CareStarter EFMP-M CareMap Request',
    requestor_type: 'EFMP-M',
    patients: [cleanPatient]
};

export const CareStatsRequestForm: FC = ({ children }) => {
    const { Form } = withTypes<FormValues>();
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const [modalOpen, setModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const { post, request } = useFetch(process.env.REACT_APP_BASE_TACFI_API_URL);
    const ages: Array<string | number> = [];
    const openModal = () => {
        setModalOpen(true);
    };
    const closeModal = () => {
        if (!request.error) {
            window.location.reload();
        }
        setModalOpen(false);
    };
    const modalBody = (
        <Card style={{ minWidth: '30vw', minHeight: '20vh' }}>
            <CardContent style={{ display: request.loading ? 'flex' : 'block' }}>
                {request.loading && <CircularProgress size={'100px'} style={{ margin: 'auto' }} />}
                {!request.loading && (
                    <>
                        {!request.error && (
                            <Typography variant="h5" align="center">
                                We&apos;ve received your request!
                            </Typography>
                        )}
                        <Typography variant="body1">{modalMessage}</Typography>
                    </>
                )}
            </CardContent>
        </Card>
    );

    async function onSubmit(values: FormValues) {
        const params = { ...values };
        params.patients.forEach((patient, i) => {
            values.patients[i].care_required = patient.care_required.filter((care) => care !== '');
            values.patients[i].behavioral_services_needed = patient.behavioral_services_needed.filter(
                (service) => service !== ''
            );
        });
        openModal();
        await post('/tacfi-stats-api-to-sns-publisher', params);
        if (request.error) {
            setModalMessage(
                'There was a problem processing your request. Please wait a few minutes and try again. If the problem persists then please contact support@carestarter.co'
            );
        }
        // else if (includesOther) {
        //     setModalMessage(
        //         'We are hard at work building your personalized CareMap. Please allow us 2 to 3 business days to build this for you. If we have any questions, we will reach out to you via email.'
        //     );
        // }
        else {
            setModalMessage(
                'Thank you! We are hard at work building your requested CareMap(s) for the patient information provided. Please allow us 2 to 3 business days to build this for you. We will follow up via the email you provided. If we have any questions, we will also reach out to you via email.'
            );
        }
    }
    return (
        <Form
            onSubmit={onSubmit}
            mutators={{
                ...arrayMutators
            }}
            initialValues={initialValues}
            validate={(values) => {
                const errors = {
                    patients: []
                } as any;
                // if (values. && (!values.diagnosis || values.diagnosis.length < 1)) {
                //     errors.diagnosis = 'Please select a diagnosis' as any;
                // }
                values.patients.forEach((patient, i) => {
                    errors.patients.push({});
                    if (patient.care_required.length === 0) {
                        errors.patients[i].care_required = 'Please select a required care service' as any;
                    }
                    if (patient.behavioral_services_needed.length === 0) {
                        errors.patients[i].behavioral_services_needed = 'Please select a service' as any;
                    }
                    if (`${patient.patient_age}`.length < 1) {
                        errors.patients[i].patient_age = 'Please enter an age between 0 and 99' as any;
                    }
                    if (!patient.patient_primary_diagnosis || patient.patient_primary_diagnosis.length < 1) {
                        errors.patients[i].patient_primary_diagnosis = 'This field is required' as any;
                    }
                });
                return errors;
            }}
            render={({ handleSubmit, submitting, form, values }) => (
                <form onSubmit={handleSubmit}>
                    {children}
                    <FormSpy
                        subscription={{ values: true }}
                        render={({ values }) => {
                            values.patients.forEach((patient: Patient, index: number) => {
                                // if (!ages[index] && patient?.patient_age) {
                                //     ages.push(patient.patient_age);
                                // }
                                // if (ages[index] !== patient?.patient_age) {
                                //     // Have to wrap the form change in a timeout to avoid breaking rules of react hooks
                                //     setTimeout(() => {
                                //         form.change(`patients[${index}].diagnosis`, []);
                                //     }, 1);
                                //     ages[index] = patient?.patient_age;
                                // }
                                const otherSelected = !!(
                                    patient?.care_required && patient.care_required?.includes('other')
                                );
                                if (values.patients[index]) {
                                    values.patients[index].other_care_required = otherSelected
                                        ? values.patients[index].other_care_required
                                        : '';
                                }
                            });
                            return null;
                        }}
                    />
                    <FieldArray name="patients">
                        {({ fields }) =>
                            fields.map((name, index) => (
                                <div key={index}>
                                    <Typography
                                        variant="h6"
                                        style={{
                                            color: prefersDarkMode ? 'White' : 'Black',
                                            fontSize: '1.5em',
                                            marginBottom: '0px',
                                            marginTop: '15px'
                                        }}
                                    >
                                        Patient {index ? index + 1 : ''}
                                    </Typography>
                                    <CSTextField
                                        required
                                        name={`${name}.patient_age`}
                                        label={`${getPatientText(index)} Age`}
                                        format={(val) => val.slice(0, 2).replace(/\D/g, '')}
                                    />
                                    <Tooltip title="From 2792 Part A section 1a. DIAGNOSIS 1" arrow placement="top">
                                        <div>
                                            <CSTextField
                                                required
                                                name={`${name}.patient_primary_diagnosis`}
                                                label={`${getPatientText(index)} Primary Diagnosis`}
                                                maxLength={20}
                                            />
                                        </div>
                                    </Tooltip>
                                    <Tooltip
                                        title="From 2792 Part B section 14. HEALTH CARE REQUIRED"
                                        arrow
                                        placement="top"
                                        PopperProps={{ disablePortal: true }}
                                    >
                                        <div>
                                            <CSCheckBoxes
                                                name={`${name}.care_required`}
                                                label={`${getPatientText(index)} Health Care Required *`}
                                                data={diagnoses}
                                                onClick={(e) => {
                                                    if (e.target.value === '') {
                                                        setTimeout(() => {
                                                            form.change(`patients[${index}].care_required` as any, [
                                                                ''
                                                            ]);
                                                            form.change(
                                                                `patients[${index}].other_care_required` as any,
                                                                ''
                                                            );
                                                        }, 200);
                                                    } else {
                                                        setTimeout(() => {
                                                            form.change(`patients[${index}].care_required` as any, [
                                                                ...values.patients[index].care_required.filter(
                                                                    (item) => item !== ''
                                                                ),
                                                                e.target.value
                                                            ]);
                                                        }, 200);
                                                    }
                                                }}
                                            />
                                        </div>
                                    </Tooltip>
                                    {values.patients[index]?.care_required?.includes('other') && (
                                        <CSTextField
                                            label={`${getPatientText(index)} Other Care Required`}
                                            name={`${name}.other_care_required`}
                                            required
                                            maxLength={20}
                                        />
                                    )}
                                    <Tooltip title="From 2792 Part B section 8. HISTORY" arrow placement="top">
                                        <div>
                                            <CSCheckBoxes
                                                name={`${name}.behavioral_services_needed`}
                                                label={`${getPatientText(index)} Behavioral Health Services Needed *`}
                                                onClick={(e) => {
                                                    if (e.target.value === '') {
                                                        setTimeout(() => {
                                                            form.change(
                                                                `patients[${index}].behavioral_services_needed` as any,
                                                                ['']
                                                            );
                                                            form.change(
                                                                `patients[${index}].behavioral_services_needed_specific` as any,
                                                                ''
                                                            );
                                                        }, 200);
                                                    } else {
                                                        setTimeout(() => {
                                                            form.change(
                                                                `patients[${index}].behavioral_services_needed` as any,
                                                                [
                                                                    ...values.patients[
                                                                        index
                                                                    ].behavioral_services_needed.filter(
                                                                        (item) => item !== ''
                                                                    ),
                                                                    e.target.value
                                                                ]
                                                            );
                                                        }, 200);
                                                    }
                                                }}
                                                data={[
                                                    { label: 'None', value: '' },
                                                    { label: 'Inpatient', value: 'behavioral_health_inpatient' },
                                                    { label: 'Outpatient', value: 'behavioral_health_outpatient' }
                                                ]}
                                            />
                                        </div>
                                    </Tooltip>
                                    {(values.patients[index]?.behavioral_services_needed?.includes(
                                        'behavioral_health_inpatient'
                                    ) ||
                                        values.patients[index]?.behavioral_services_needed?.includes(
                                            'behavioral_health_outpatient'
                                        )) && (
                                        <CSTextField
                                            label={`Specify`}
                                            name={`${name}.behavioral_services_needed_specific`}
                                            required
                                            maxLength={50}
                                        />
                                    )}
                                    <Tooltip
                                        title="From 2792 Part B section 16. ARTIFICIAL OPENINGS / PROSTHETICS; section 18. MEDICALLY NECESSARY ADAPTIVE EQUIPMENT / SPECIAL MEDICAL EQUIPMENT"
                                        arrow
                                        placement="top"
                                    >
                                        <div>
                                            <CSSelectField
                                                required
                                                name={`${name}.med_supplies_needed`}
                                                label={`${getPatientText(index)} Durable Medical Supplies Needed`}
                                            >
                                                <option aria-label="None" value="" />
                                                <option value={1}>Yes</option>
                                                <option value={0}>No</option>
                                            </CSSelectField>
                                        </div>
                                    </Tooltip>
                                    {index > 0 && (
                                        <div style={{ textAlign: 'right', marginBottom: '10px' }}>
                                            <Button
                                                disabled={submitting}
                                                onClick={() => {
                                                    ages.splice(index, 1);
                                                    form.mutators.remove(name.split('[')[0], index);
                                                }}
                                                style={{ marginTop: '10px' }}
                                                variant="contained"
                                                type="button"
                                                size="small"
                                            >
                                                Remove patient{' '}
                                                <HighlightOffIcon fontSize="small" style={{ marginLeft: '5px' }} />
                                            </Button>
                                        </div>
                                    )}
                                    {index > 0 && <Divider />}
                                </div>
                            ))
                        }
                    </FieldArray>
                    <Button
                        disabled={submitting}
                        onClick={() => form.mutators.push('patients', cleanPatient)}
                        style={{ marginTop: '10px' }}
                        variant="contained"
                        type="button"
                        size="small"
                    >
                        Add another patient <AddCircleOutlineIcon fontSize="small" style={{ marginLeft: '5px' }} />
                    </Button>
                    <Typography style={{ marginTop: '10px' }} variant="caption" display="block" gutterBottom>
                        CareStarter is committed to protecting and respecting data privacy, and we&lsquo;ll only use the
                        personal information provided to communicate and deliver healthcare provider related resources.
                        By submitting this form, you consent to us contacting you for these purposes.
                    </Typography>
                    <Button disabled={submitting} style={{ marginTop: '10px' }} variant="contained" type="submit">
                        Request caremap!
                    </Button>
                    <Dialog open={modalOpen} onClose={closeModal}>
                        <DialogTitle style={{ padding: '0px' }} disableTypography id="id">
                            <Box display="flex" alignItems="center">
                                <Box flexGrow={1}></Box>
                                <Box>
                                    <IconButton onClick={closeModal}>
                                        <CloseIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                        </DialogTitle>
                        {modalBody}
                    </Dialog>
                </form>
            )}
        />
    );
};
