export const diagnosisList = {
    childhood: [
        'Asthma',
        'Attention Deficit/Hyperactivity Disorder (ADHD)',
        'Autism(ASD)',
        'Celiac',
        'Cerebral Palsy',
        'CHARGE Syndrome',
        'Developmental Delay',
        'Down Syndrome',
        'Eczema',
        'Epilepsy',
        'General – Heart-Blood-Lung',
        'Hydrocephalus',
        'Sensory Processing Disorder',
        'Speech Impairment',
        'Visual Impairment',
        'Well Care'
    ],
    education: [
        'Anxiety',
        'Asthma',
        'Attention Deficit Disorder',
        'Autism(ASD)',
        'Celiac',
        'Cerebral Palsy',
        'Developmental/Learning Disorder',
        'Down Syndrome',
        'Dual Sensory Impairment',
        'Epilepsy',
        'General – Heart-Blood-Lung',
        'Global Developmental Delay',
        'Hearing Impairment',
        'Oppositional Defiance Disorder',
        'Sensory Processing Disorder',
        'Speech Impairment',
        'Visual Impairment',
        'Well Care'
    ],
    transition: [
        'Anxiety',
        'Attention Deficit Disorder',
        'Autism(ASD)',
        'Celiac',
        'Cerebral Palsy',
        'Developmental/Learning Disorder',
        'Diabetes',
        'Down Syndrome',
        'General – Heart-Blood-Lung',
        'General Orthopedic',
        'Nutritional Disorders',
        'Oppositional Defiance Disorder',
        'Schizophrenia',
        'Sensory Processing Disorder',
        'Speech Impairment',
        'Visual Impairment',
        'Well Care'
    ],
    adult: [
        'Acute Lymphocytic Leukemia',
        'Allergies',
        'Antiphospholipid Syndrome',
        'Anxiety',
        'Asthma',
        'Autism(ASD)',
        'Bipolar Disorder (BP)',
        'Depression',
        'Down Syndrome',
        'Dysthymia / Persistent Depressive Disorder (PDD)',
        'General Cancer',
        'General – Heart-Blood-Lung',
        'General Orthopedic',
        'Hypothyroidism',
        'Kidney Disease',
        'Obesity',
        'Polycystic Ovary Syndrome',
        'Sleep Apnea',
        'Substance Abuse',
        'Visual Impairment',
        'Well Care'
    ]
};
