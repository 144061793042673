import React, { FC } from 'react';
import { withTypes } from 'react-final-form';
import useFetch from 'use-http';
import { nocMap } from '../../resources/noc-map';

type Age = '0 to 3 (Early childhood)' | '4 to 12 (Education)' | '13 to 21 (Transition)' | '22 and older (Adult)';

interface FormValues {
    email: string;
    base: string;
    age: Age;
    diagnosis: string;
    otherDiagnosis: string;
}

export const NOCRequestForm: FC = ({ children }) => {
    const { Form } = withTypes<FormValues>();
    const { post } = useFetch('https://0tdzcixwtk.execute-api.us-east-1.amazonaws.com');

    async function onSubmit(values: FormValues) {
        const pathMap = nocMap[values.age];
        type pathMapType = typeof pathMap;
        const diagnosis = values.diagnosis as keyof pathMapType;
        window.open(window.location.origin + pathMap[diagnosis]);
        await post('/noc-request', values);
        window.location.reload();
    }

    return (
        <>
            <Form
                onSubmit={onSubmit}
                initialValues={{ base: 'Travis AFB' } as FormValues}
                render={({ handleSubmit }) => <form onSubmit={handleSubmit}>{children}</form>}
            />
        </>
    );
};
