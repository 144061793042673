import React, { FC, useState } from 'react';
import { IonContent, IonPage, IonGrid } from '@ionic/react';
import { FormHelperText, Link } from '@material-ui/core';
import { Field, FormSpy } from 'react-final-form';
import { TextField } from 'mui-rff';
import { LogoCard } from '../../components/LogoCard';
import { CareStatsRequestForm } from './CareStatsSheetRequestForm';
import { CSEmailField, CSTextField, CSPhoneField } from '../../components/Fields';

// const Li: FC = ({ children }) => <li style={{ marginBottom: '5px' }}>{children}</li>;

const Title = <>CareStarter EFMP CareMap&trade; Request</>;

const Subtitle = (
    <>
        <p>
            Please complete all CareMap Stat Sheet questions (corresponds with the DD2792 Form) to evaluate resources
            available for each EFMP travel determination. Add all family members within one submission. Submit completed
            form by clicking the Request CareMap button.
            <br />
            <br />
            Have any questions or further submission needs? Please contact Jessica Hulter at{' '}
            <Link href="mailto:jhulter@carestarter.co">jhulter@carestarter.co</Link>.
        </p>
        {/* <p>
            <strong>How to use this form</strong>
        </p>
        <ol style={{ marginBottom: '0px' }}>
            <Li>Let us know who you are. Select the best option that represents you as the Requestor.</Li>
            <Li>Provide the Caregiver’s contact information.</Li>
            <Li>Provide the Patient’s age and diagnoses.</Li>
        </ol>
        <ol style={{ marginLeft: '10px', marginTop: '0px' }} type="a">
            <Li>
                If your patient&apos;s diagnoses are not listed, select &quot;Other&quot; and enter them in the
                &quot;Other Diagnoses&quot; field.
            </Li>
            <Li>
                If you have multiple patients, click the &quot;Add Another Patient&quot; button to complete a Patient
                Profile for each member you are submitting a CareMap request for.
            </Li>
        </ol>
        <p>
            <strong>For all EFMP Family, non-EFMP Family and Civilian members</strong>
        </p>
        <p>
            Caregivers are encouraged to visit the{' '}
            <Link href="https://carestarter.co/efmp" target="_blank">
                Travis Pilot
            </Link>{' '}
            page for directions on downloading the CareStarter App and to complete a{' '}
            <Link href="https://carestarter.co/blog/how-to-create-patient-profiles" target="_blank">
                Patient Profile
            </Link>{' '}
            within the app to expedite receiving their resources.
        </p> */}
    </>
);

const CareStatsSheetRequestPage: FC = () => {
    const [showEmailHelper, setShowEmailHelper] = useState(true);
    return (
        <IonPage>
            <IonContent fullscreen>
                <IonGrid>
                    <LogoCard title={Title} subtitle={Subtitle}>
                        <CareStatsRequestForm>
                            <FormSpy
                                subscription={{ values: true }}
                                render={({ values }) => {
                                    setShowEmailHelper(!(values.email && values.email.length));
                                    return null;
                                }}
                            />
                            <CSEmailField label="EFMP-M Requestor Email" name="requestor_email" required />
                            <CSTextField label="Caregiver First Name" name="caregiver_first_name" required />
                            <CSTextField label="Caregiver Last Name" name="caregiver_last_name" required />
                            <CSEmailField label="Caregiver Email" name="caregiver_email" required />
                            {showEmailHelper && <FormHelperText>Civilian email recommended</FormHelperText>}
                            <CSPhoneField label="Caregiver Mobile Phone (xxx-xxx-xxxx)" name="caregiver_mobile_phone" />
                            <TextField
                                label="Base"
                                name="base"
                                required
                                value="Travis Air Force BASE - EFMP"
                                placeholder="Travis Air Force BASE - EFMP"
                                disabled
                            />
                            <Field name="requestor_type" component="input" type="hidden" required value="EFMP-M" />
                        </CareStatsRequestForm>
                    </LogoCard>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default CareStatsSheetRequestPage;
