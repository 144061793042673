import { IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent } from '@ionic/react';
import React, { FC, ReactElement } from 'react';

interface Props {
    title: string | ReactElement;
    subtitle: string | ReactElement;
}

const centeredMargin = { marginLeft: 'auto', marginRight: 'auto' };

export const LogoCard: FC<Props> = ({ title, subtitle, children }) => (
    <IonCard style={{ maxWidth: '900px', ...centeredMargin }}>
        <img
            style={{ padding: '10px', display: 'block', ...centeredMargin }}
            alt="CareStarter Logo"
            src="/assets/CS-CareMap-Request-Header-June2023.jpg"
        />
        <IonCardHeader>
            <IonCardTitle>{title}</IonCardTitle>
            <IonCardSubtitle>{subtitle}</IonCardSubtitle>
        </IonCardHeader>
        <IonCardContent>{children}</IonCardContent>
    </IonCard>
);
