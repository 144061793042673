import React, { useState } from 'react';
import { IonContent, IonPage, IonGrid } from '@ionic/react';
import { Button, Link, Typography } from '@material-ui/core';
import { FormSpy } from 'react-final-form';
import { LogoCard } from '../../components/LogoCard';

import { Age, FormattedAge } from '../CareMapRequest/types';
import { diagnosisList } from '../../resources/diagnosis';
import { CSSelectField } from '../../components/Fields';
import { NOCRequestForm } from './form';
import { TextField } from 'mui-rff';

const Title = <>CareStarter EFMP Network of Care Score Tool</>;

const Subtitle = (
    <>
        For the specific age range and diagnosis you select, the Network of Care Score Tool will show you the number of
        resources sent to the family and the areas of care that were covered.
    </>
);

const formatAge = (str: Age): FormattedAge => {
    const formatted = str.split('(')[1].split(')')[0].toLowerCase();
    return formatted.split(' ').length > 1 ? (formatted.split(' ')[1] as FormattedAge) : (formatted as FormattedAge);
};

const CareScoreRequestPage: React.FC = () => {
    const [age, setAge] = useState<FormattedAge>('' as FormattedAge);

    return (
        <IonPage>
            <IonContent fullscreen>
                <IonGrid>
                    <LogoCard title={Title} subtitle={Subtitle}>
                        <NOCRequestForm>
                            <FormSpy
                                subscription={{ values: true }}
                                onChange={({ values }) => {
                                    if (values.age) {
                                        const formattedAge = formatAge(values.age);
                                        if (formattedAge !== age) {
                                            values.diagnosis = '';
                                            // values.otherDiagnosis = '';
                                        }
                                        setAge(formattedAge);
                                    }
                                    // const otherSelected = !!(values.diagnosis && values.diagnosis.includes('Other'));
                                    // if (values.diagnosis || values.diagnosis === '') {
                                    //     setShowOtherDiagnosis(otherSelected);
                                    // }
                                    // values.otherDiagnosis = otherSelected ? values.otherDiagnosis : '';
                                }}
                            />
                            <TextField
                                label="Base"
                                name="base"
                                required
                                value="Travis AFB"
                                placeholder="Travis AFB"
                                disabled
                            />
                            <CSSelectField required name="age" label="Patient Age">
                                <option aria-label="None" value="" />
                                <option value="0 to 3 (Early childhood)">0 to 3</option>
                                <option value="4 to 12 (Education)">4 to 12</option>
                                <option value="13 to 21 (Transition)">13 to 21</option>
                                <option value="22 and older (Adult)">22 and older</option>
                            </CSSelectField>
                            {age && diagnosisList[age] && (
                                <CSSelectField required name="diagnosis" label="Patient Diagnosis">
                                    <option aria-label="None" value="" />
                                    {diagnosisList[age].map((item, i) => (
                                        <option value={item} key={i}>
                                            {item}
                                        </option>
                                    ))}
                                    {/* <option value="Other">Other</option> */}
                                </CSSelectField>
                            )}
                            {/* {showOtherDiagnosis && (
                                <CSTextField label="Other Diagnosis" name="otherDiagnosis" required />
                            )}
                            {showOtherDiagnosis && <CSEmailField label="Email" name="email" required />} */}
                            <Typography style={{ marginTop: '5px' }} variant="caption" display="block" gutterBottom>
                                Network of Care Score images are available for the diagnoses listed in the drop down
                                menus. If you would like CareMap information about other diagnoses please email{' '}
                                <Link href="mailto:efmp@carestarter.co">efmp@carestarer.co</Link>.
                            </Typography>
                            <Button style={{ marginTop: '10px' }} variant="contained" type="submit">
                                Get your network of care score!
                            </Button>
                        </NOCRequestForm>
                    </LogoCard>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default CareScoreRequestPage;
