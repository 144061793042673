import React, { FC, useState } from 'react';
import { IonContent, IonPage, IonGrid } from '@ionic/react';
import { FormHelperText, Link, Tooltip } from '@material-ui/core';
import { Field, FormSpy } from 'react-final-form';
import { TextField } from 'mui-rff';
import { LogoCard } from '../../components/LogoCard';
import { EFMPCareMapRequestForm } from './EFMPCareMapRequestForm';
import { CSEmailField, CSTextField, CSPhoneField, CSSelectField, CSSelectFieldToolTip } from '../../components/Fields';

// const Li: FC = ({ children }) => <li style={{ marginBottom: '5px' }}>{children}</li>;

const Title = <>CareStarter CareMap&trade; Request</>;

const requesterToolTip = (
    <>
        <p>
            <b>EFMP Family</b>
            <br />
            If you are the patient, parent, or caregiver of a patient for which the CareMap is being requested, and your
            family is enrolled in the Exceptional Family Member Program through your branch of service.
            <br />
            <br />
            <b>Other Military Family Support</b>
            <br />
            If you work on a military installation and are requesting services on behalf of a family not your own, but
            ARE NOT working for either EFMP-Medical or EFMP-Family Support.
            <br />
            <br />
            <b>Non-EFMP Family</b>
            <br />
            If you are the patient, parent, or caregiver of a patient for which the CareMap is being requested, and your
            family is NOT enrolled in the Exceptional Family Member Program through your branch of service.
        </p>
    </>
);
const familyTypeToolTip = (
    <>
        <p>
            <b>Inbound Family</b>
            <br />
            The person for whom the CareMap is being requested is anticipating a move to the requested service location.
            <br />
            <br />
            <b>Existing EFMP Family</b>
            <br />
            The person for whom the CareMap is being requested is already living in the requested service location.
            <br />
            <br />
            <b>Newly Enrolled EFMP Family</b>
            <br />
            The person for whom the CareMap is being requested is already living in the requested service location, AND
            is a new first-time enrollee of the Exceptional Family Member Program.
            <br />
            <br />
            <b>Reassignment Request</b>
            <br />
            The person for whom the CareMap is being requested is already living in the requested service location, is
            enrolled in the Exceptional Family Member Program, and is requesting a reassignment based on lack of
            available services for their EFMP enrolled dependent.
            <br />
            <br />
            <b>Non-EFMP Family</b>
            <br />
            The person for whom the CareMap is being requested is not enrolled in the Exceptional Family Member Program
            through their branch of service, but would like a personalized list of resources.
        </p>
    </>
);

const Subtitle = (
    <>
        <p>
            We extend our heartfelt gratitude to you for your patience and active participation in our pilot program.
            Your invaluable input and engagement have been instrumental in shaping and refining our application,
            ensuring a robust and user-centric experience. Thank you for being a part of this journey with CareStarter!
            <br />
            <br />
            Please complete all CareMap questions (corresponds with the DD2792 Form) to receive resources for each
            dependent requiring a CareMap. Add all eligible family members within one submission. Submit completed form
            by clicking the Request CareMap button.
            <br />
            <br />
            Have any questions or further submission needs? Please contact Jessica Hulter at{' '}
            <Link href="mailto:jhulter@carestarter.co">jhulter@carestarter.co</Link>.
            <br />
            <br />
            For requests not requiring medical or therapeutic services, basic resources will be provided. Please use
            contact above to request additional specific resources.
        </p>
        {/* <p>
            <strong>How to use this form</strong>
        </p>
        <ol style={{ marginBottom: '0px' }}>
            <Li>Let us know who you are. Select the best option that represents you as the Requestor.</Li>
            <Li>Provide the Caregiver’s contact information.</Li>
            <Li>Provide the Patient’s age and diagnoses.</Li>
        </ol>
        <ol style={{ marginLeft: '10px', marginTop: '0px' }} type="a">
            <Li>
                If your patient&apos;s diagnoses are not listed, select &quot;Other&quot; and enter them in the
                &quot;Other Diagnoses&quot; field.
            </Li>
            <Li>
                If you have multiple patients, click the &quot;Add Another Patient&quot; button to complete a Patient
                Profile for each member you are submitting a CareMap request for.
            </Li>
        </ol>
        <p>
            <strong>For all EFMP Family, non-EFMP Family and Civilian members</strong>
        </p>
        <p>
            Caregivers are encouraged to visit the{' '}
            <Link href="https://carestarter.co/efmp" target="_blank">
                Travis Pilot
            </Link>{' '}
            page for directions on downloading the CareStarter App and to complete a{' '}
            <Link href="https://carestarter.co/blog/how-to-create-patient-profiles" target="_blank">
                Patient Profile
            </Link>{' '}
            within the app to expedite receiving their resources.
        </p> */}
    </>
);

const EFMPCareMapRequestPage: FC = () => {
    const [showEmailHelper, setShowEmailHelper] = useState(true);
    const [showOptInForm, setOptInForm] = useState(false);
    return (
        <IonPage>
            <IonContent fullscreen>
                <IonGrid>
                    <LogoCard title={Title} subtitle={Subtitle}>
                        <EFMPCareMapRequestForm>
                            <FormSpy
                                subscription={{ values: true }}
                                render={({ values }) => {
                                    setShowEmailHelper(!(values.email && values.email.length));
                                    setOptInForm(
                                        values.requestor_type === 'Non-EFMP Family' ||
                                            values.requestor_type === 'EFMP Family'
                                    );
                                    if (
                                        values.requestor_type !== 'Non-EFMP Family' &&
                                        values.requestor_type !== 'EFMP Family'
                                    ) {
                                        values.newsletter_opt_in_flg = '';
                                    }

                                    return null;
                                }}
                            />
                            <Tooltip title="From 2792 Part A section 1a. DIAGNOSIS 1" arrow placement="top">
                                <CSSelectFieldToolTip
                                    required
                                    label={`Requestor Type`}
                                    name={'requestor_type'}
                                    tooltip={requesterToolTip}
                                >
                                    <option aria-label="None" value="" />
                                    <option value={'EFMP Family'}>EFMP Family</option>
                                    {/* <option value={'EFMP-M'}>EFMP-M (Medical Staff)</option> */}
                                    {/* <option value={'EFMP-FS'}>EFMP-FS (Family Support Staff)</option> */}
                                    <option value={'Other Family Support'}>Other Military Family Support</option>
                                    <option value={'Non-EFMP Family'}>Non-EFMP Family</option>
                                </CSSelectFieldToolTip>
                            </Tooltip>
                            <CSEmailField label="Requestor Email" name="requestor_email" required />
                            <CSSelectFieldToolTip
                                required
                                label={`Family Type`}
                                name={'family_type'}
                                tooltip={familyTypeToolTip}
                            >
                                <option aria-label="None" value="" />
                                <option value={'Inbound EFMP Family'}>Inbound EFMP Family</option>
                                <option value={'Existing EFMP Family'}>Existing EFMP Family</option>
                                <option value={'Newly Enrolled EFMP Family'}>Newly Enrolled EFMP Family</option>
                                <option value={'Reassignment Request'}>Reassignment Request</option>
                                <option value={'Non-EFMP Family'}>Non-EFMP Family</option>
                            </CSSelectFieldToolTip>
                            <CSTextField label="Caregiver First Name" name="caregiver_first_name" required />
                            <CSTextField label="Caregiver Last Name" name="caregiver_last_name" required />
                            <CSEmailField label="Caregiver Email" name="caregiver_email" required />
                            {showEmailHelper && (
                                <FormHelperText>
                                    Civilian email recommended. CareMaps and CareMap Support will be sent via email.
                                </FormHelperText>
                            )}
                            {showOptInForm && (
                                <>
                                    <CSSelectField required label="Newsletter Opt-In" name={'newsletter_opt_in_flg'}>
                                        <option aria-label="None" value="" />
                                        <option value={'1'}>Yes</option>
                                        <option value={'0'}>No</option>
                                    </CSSelectField>
                                    <FormHelperText style={{ paddingTop: '10px' }}>
                                        Yes, I would like to receive the Travis AFB CareStarter Newsletter, with
                                        additional resource and support outreach!
                                    </FormHelperText>
                                </>
                            )}
                            <CSPhoneField label="Caregiver Mobile Phone (xxx-xxx-xxxx)" name="caregiver_mobile_phone" />
                            <TextField
                                label="Base"
                                name="base"
                                required
                                value="Travis Air Force BASE - EFMP"
                                placeholder="Travis Air Force BASE - EFMP"
                                disabled
                            />
                            <Field name="requestor_type" component="input" type="hidden" required value="EFMP-M" />
                        </EFMPCareMapRequestForm>
                    </LogoCard>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default EFMPCareMapRequestPage;
