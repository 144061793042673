export const diagnoses = [
    { value: '', label: 'N/A' },
    { value: 'gynecologist', label: 'GYNECOLOGIST' },
    { value: 'pediatric_surgeon', label: 'PEDIATRIC SURGEON' },
    { value: 'allergist_immunologist', label: 'ALLERGIST / IMMUNOLOGIST' },
    { value: 'gynecologist_oncologist', label: 'GYNECOLOGIST / ONCOLOGIST' },
    { value: 'physiatrist', label: 'PHYSIATRIST' },
    { value: 'applied_behavior_analyst', label: 'APPLIED BEHAVIOR ANALYST' },
    { value: 'hematologist_oncologist', label: 'HEMATOLOGIST / ONCOLOGIST' },
    { value: 'physical_therapist', label: 'PHYSICAL THERAPIST' },
    { value: 'audiologist', label: 'AUDIOLOGIST' },
    { value: 'infectious_disease', label: 'INFECTIOUS DISEASE' },
    { value: 'plastic_surgeon', label: 'PLASTIC SURGEON' },
    { value: 'behavior_analyst', label: 'BEHAVIOR ANALYST' },
    { value: 'internist', label: 'INTERNIST' },
    { value: 'podiatrist', label: 'PODIATRIST' },
    { value: 'cardiac_thoracic_surgeon', label: 'CARDIAC / THORACIC SURGEON' },
    { value: 'nephrologist', label: 'NEPHROLOGIST' },
    { value: 'psychiatrist', label: 'PSYCHIATRIST' },
    { value: 'cardiologist', label: 'CARDIOLOGIST' },
    { value: 'neurologist', label: 'NEUROLOGIST' },
    { value: 'psychiatrist_nurse_practitioner', label: 'PSYCHIATRIST NURSE PRACTITIONER' },
    { value: 'cleft_palate_team_pediatric', label: 'CLEFT PALATE TEAM - PEDIATRIC' },
    { value: 'neuropsychiatrist', label: 'NEUROPSYCHIATRIST' },
    { value: 'psychologist', label: 'PSYCHOLOGIST' },
    { value: 'counselor', label: 'COUNSELOR' },
    { value: 'neuropsychologist', label: 'NEUROPSYCHOLOGIST' },
    { value: 'pulmonologist', label: 'PULMONOLOGIST' },
    { value: 'dermatologist', label: 'DERMATOLOGIST' },
    { value: 'neurosurgeon', label: 'NEUROSURGEON' },
    { value: 'radiation_oncologist', label: 'RADIATION ONCOLOGIST' },
    { value: 'developmental_pediatrician', label: 'DEVELOPMENTAL PEDIATRICIAN' },
    { value: 'occupational_therapist', label: 'OCCUPATIONAL THERAPIST' },
    { value: 'respiratory_therapist', label: 'RESPIRATORY THERAPIST' },
    { value: 'dialysis_team', label: 'DIALYSIS TEAM' },
    { value: 'ophthalmologist', label: 'OPHTHALMOLOGIST' },
    { value: 'rheumatologist', label: 'RHEUMATOLOGIST' },
    { value: 'dietary_nutrition_specialist', label: 'DIETARY / NUTRITION SPECIALIST' },
    { value: 'oral_surgeon', label: 'ORAL SURGEON' },
    { value: 'social_worker', label: 'SOCIAL WORKER' },
    { value: 'endocrinologist', label: 'ENDOCRINOLOGIST' },
    { value: 'orthopedic_surgeon', label: 'ORTHOPEDIC SURGEON' },
    { value: 'speech_and_language_pathologist', label: 'SPEECH AND LANGUAGE PATHOLOGIST' },
    { value: 'family_practitioner', label: 'FAMILY PRACTITIONER' },
    { value: 'otorhinolaryngologist', label: 'OTORHINOLARYNGOLOGIST' },
    { value: 'transplant_team', label: 'TRANSPLANT TEAM' },
    { value: 'gastroenterologist', label: 'GASTROENTEROLOGIST' },
    { value: 'pain_clinic', label: 'PAIN CLINIC' },
    { value: 'urologist', label: 'UROLOGIST' },
    { value: 'general_surgeon', label: 'GENERAL SURGEON' },
    { value: 'pediatric_nurse_practitioner', label: 'PEDIATRIC NURSE PRACTITIONER' },
    { value: 'vascular_surgeon', label: 'VASCULAR SURGEON' },
    { value: 'genetics', label: 'GENETICS' },
    { value: 'pediatrician', label: 'PEDIATRICIAN' },
    { value: 'other', label: 'Other' }
];
