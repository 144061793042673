import React, { FC, useState } from 'react';
import {
    Button,
    Card,
    CardContent,
    Dialog,
    Typography,
    DialogTitle,
    Box,
    IconButton,
    CircularProgress
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { withTypes } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import useFetch from 'use-http';
import { FormValues } from './types';

const initialValues = {
    base: 'Travis Air Force Base - EFMP #16888',
    form_name: 'CareStarter EFMP-M CareMap Request',
    requestor_type: 'EFMP-M'
};

export const CareMapRequestFormV2: FC = ({ children }) => {
    const { Form } = withTypes<FormValues>();
    const [modalOpen, setModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const { post, request } = useFetch(process.env.REACT_APP_BASE_TACFI_API_URL);
    const openModal = () => {
        setModalOpen(true);
    };
    const closeModal = () => {
        if (!request.error) {
            window.location.reload();
        }
        setModalOpen(false);
    };
    const modalBody = (
        <Card style={{ minWidth: '30vw', minHeight: '20vh' }}>
            <CardContent style={{ display: request.loading ? 'flex' : 'block' }}>
                {request.loading && <CircularProgress size={'100px'} style={{ margin: 'auto' }} />}
                {!request.loading && (
                    <>
                        {!request.error && (
                            <Typography variant="h5" align="center">
                                We&apos;ve received your request!
                            </Typography>
                        )}
                        <Typography variant="body1">{modalMessage}</Typography>
                    </>
                )}
            </CardContent>
        </Card>
    );

    async function onSubmit(values: FormValues) {
        const params: any = { ...values };
        openModal();
        await post('/tacfi-caremap-api-to-sns-publisher', params);
        if (request.error) {
            setModalMessage(
                'There was a problem processing your request. Please wait a few minutes and try again. If the problem persists then please contact support@carestarter.co'
            );
        } else {
            setModalMessage(
                "Thank you! We are hard at work building your requested CareMap. Please allow us 2 to 3 business days to send this to the provided caregiver's email. If we have any questions, we will also reach out to you via email."
            );
        }
    }
    return (
        <Form
            onSubmit={onSubmit}
            mutators={{
                ...arrayMutators
            }}
            initialValues={initialValues}
            render={({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit}>
                    {children}
                    <Typography style={{ marginTop: '10px' }} variant="caption" display="block" gutterBottom>
                        CareStarter is committed to protecting and respecting data privacy, and we&lsquo;ll only use the
                        personal information provided to communicate and deliver healthcare provider related resources
                        to the provided caregiver. By submitting this form, you consent to these purposes.
                    </Typography>
                    <Button disabled={submitting} style={{ marginTop: '10px' }} variant="contained" type="submit">
                        Request caremap!
                    </Button>
                    <Dialog open={modalOpen} onClose={closeModal}>
                        <DialogTitle style={{ padding: '0px' }} disableTypography id="id">
                            <Box display="flex" alignItems="center">
                                <Box flexGrow={1}></Box>
                                <Box>
                                    <IconButton onClick={closeModal}>
                                        <CloseIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                        </DialogTitle>
                        {modalBody}
                    </Dialog>
                </form>
            )}
        />
    );
};
