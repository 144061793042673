import React from 'react';
import { IonApp, IonRouterOutlet } from '@ionic/react';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
// import '@ionic/react/css/padding.css';
// import '@ionic/react/css/float-elements.css';
// import '@ionic/react/css/text-alignment.css';
// import '@ionic/react/css/text-transformation.css';
// import '@ionic/react/css/flex-utils.css';
// import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import { createTheme, ThemeProvider, useMediaQuery } from '@material-ui/core';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router';
import CareScoreRequestPage from './pages/CareScoreRequest';
import CareStatsSheetRequestPage from './pages/CareStatsSheetRequest';
import EFMPCareMapRequestPage from './pages/EFMPCareMapRequest';
import CareMapRequestPageV2 from './pages/CareMapRequestV2';
import DiseaseCareMapRequestPage from './pages/DiseaseCareMapRequest';

const App: React.FC = () => {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const theme = React.useMemo(
        () =>
            createTheme({
                palette: {
                    type: prefersDarkMode ? 'dark' : 'light'
                },
                props: {
                    MuiTextField: {
                        margin: 'dense'
                    },
                    MuiFormControl: {
                        margin: 'dense'
                    }
                }
            }),
        [prefersDarkMode]
    );
    return (
        <IonApp>
            <ThemeProvider theme={theme}>
                <IonReactRouter>
                    <IonRouterOutlet>
                        <Redirect exact from="/" to="/caremap-request" />
                        <Route path="/orig-caremap-request" component={CareMapRequestPageV2} />
                        <Route path="/nocs-request" component={CareScoreRequestPage} />
                        <Route path="/orig-caremap-stats-request" component={CareStatsSheetRequestPage} />
                        <Route path="/caremap-request" component={EFMPCareMapRequestPage} />
                        <Route path="/disease-management-caremap-request" component={DiseaseCareMapRequestPage} />
                    </IonRouterOutlet>
                </IonReactRouter>
            </ThemeProvider>
        </IonApp>
    );
};

export default App;
