import React, { FC, useState } from 'react';
import { IonContent, IonPage, IonGrid } from '@ionic/react';
import { FormHelperText } from '@material-ui/core';
import { Field, FormSpy } from 'react-final-form';
import { TextField } from 'mui-rff';
import { LogoCard } from '../../components/LogoCard';
import { CareMapRequestFormV2 } from './CareMapRequestFormV2';
import { CSEmailField, CSTextField, CSPhoneField } from '../../components/Fields';

// const Li: FC = ({ children }) => <li style={{ marginBottom: '5px' }}>{children}</li>;

const Title = <>CareStarter EFMP CareMap&trade; Request</>;

const Subtitle = (
    <>
        Please complete the following CareMap Request Form. Caregiver name and contact information should correspond to
        your previously submitted <strong>CareMap</strong>. Submit completed form by clicking the{' '}
        <strong>Request CareMap</strong> button and the CareStarter Team will generate and deliver a CareMap to the
        specified caregiver.
    </>
);

const CareMapRequestPageV2: FC = () => {
    const [showEmailHelper, setShowEmailHelper] = useState(true);
    return (
        <IonPage>
            <IonContent fullscreen>
                <IonGrid>
                    <LogoCard title={Title} subtitle={Subtitle}>
                        <CareMapRequestFormV2>
                            <FormSpy
                                subscription={{ values: true }}
                                render={({ values }) => {
                                    setShowEmailHelper(!(values.email && values.email.length));
                                    return null;
                                }}
                            />
                            <CSEmailField label="EFMP-M Requestor Email" name="requestor_email" required />
                            <CSTextField label="Caregiver First Name" name="caregiver_first_name" required />
                            <CSTextField label="Caregiver Last Name" name="caregiver_last_name" required />
                            <CSEmailField label="Caregiver Email" name="caregiver_email" required />
                            {showEmailHelper && <FormHelperText>Civilian email recommended</FormHelperText>}
                            <CSPhoneField label="Caregiver Mobile Phone (xxx-xxx-xxxx)" name="caregiver_mobile_phone" />
                            <TextField
                                label="Base"
                                name="base"
                                required
                                value="Travis Air Force BASE - EFMP"
                                placeholder="Travis Air Force BASE - EFMP"
                                disabled
                            />
                            <Field name="requestor_type" component="input" type="hidden" required value="EFMP-M" />
                        </CareMapRequestFormV2>
                    </LogoCard>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default CareMapRequestPageV2;
